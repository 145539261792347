/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useLayout, ILayout, getLayout, LayoutSetup} from '../../../../_metronic/layout/core'
import {DataSourceCard} from '../../../../_metronic/partials/content/cards/DataSourceCard'
// import {CreateUpdateDataSourceModal} from './components/modals/CreateUpdateDataSource'
import {Horizontal} from '../../wizards/components/Horizontal'
import {Card3} from '../../../../_metronic/partials/content/cards/Card3'
import {DataSourceModel} from '../../../models/dataSource_model'
import {useAuth0} from '@auth0/auth0-react'
import {getAllDataSourcesByUserId} from '../../../services/dataSourceService'
import { DataSourceSkeleton } from '../../loading_skeleton/datasource_skeleton'
import { DeploymentModel } from '../../../models/deployment_model'
import { DataSourceEmptyModal } from '../dataSources/components/DataSourceEmptyModal'
import { DeploymentList } from './DeploymentList'
import { VersionControlSkeleton } from '../../loading_skeleton/version_control_skeleton'
import { VersionControlCard } from '../../../../_metronic/partials/content/cards/VersionControlCard'
import { DeploymentControlSkeleton } from '../../loading_skeleton/deployment_configs_skeleton'

const DeploymentPage: React.FC = () => {
    const {user} = useAuth0()
    const [loading, setLoading] = useState(false)
  
    // State to store filtered workspace data
    const [gitAccountData, setGitAccountData] = useState<DeploymentModel[]>([])
  
    //--gitlab--
    const [gitLabAccountData, setGitLabAccountData] = useState<Array<DeploymentModel>>([])
    //--otherssss--
  
    // useEffect(() => {
    //   // Fetch the git account when the component mounts
    //   const userId = user?.sub
    //   getAllGitAccount(userId || "")
    //     .then((data) => {
    //       setGitAccountData(data)
    //       setLoading(false) // Set loading to false after data is received
    //     })
    //     .catch((error) => {
    //       // Handle errors if needed
    //       console.error('Error fetching datasources:', error)
    //       setLoading(false) // Set loading to false on error
    //     })
    // }, [])
  
    //update data in the primary component without any API calls (CREATE, UPDATE, DELETE)
    const updateTemporaryDataSources = (tempGitAccount: DeploymentModel, action: String) => {
      setGitAccountData((prevData) => {
        return [...prevData, tempGitAccount]
  
  
      // If the action is DELETE, remove the data source
      // if (action === 'DELETE') {
      //   return prevData.filter(
      //     (gitAccount) => gitAccount.gitaccountId !== tempGitAccount.gitaccountId
      //   )
      // }
      // Find the index of the application to update, if it exists
      // const index = prevData?.findIndex(
      //   (dataSource) => dataSource.gitaccountId === tempGitAccount.gitaccountId
      // )
      // if (index !== -1) {
      //   // If the application already exists in the array, update it
      //   const updatedApplications = [...prevData]
      //   updatedApplications[index] = tempGitAccount
      //   return updatedApplications
      // } else {
        // Create a new array by adding the tempDataSource to the existing data
        // return [...prevData, tempGitAccount]
      // }
      })
    }
  
    return (
      <>
        {loading ? (
          <DeploymentControlSkeleton />
        ) : (
          <DeploymentList
            gitAccounts={gitAccountData || undefined}
            updateTemporaryGitAccount={updateTemporaryDataSources}
          />
        )}
      </>
    )
}

export {DeploymentPage}
