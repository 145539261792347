// Define action types
export const SET_OBJECTS_DATA = 'SET_OBJECTS_DATA'

// Define action creators
export const setObjectsData = (data) => {
  return {
    type: SET_OBJECTS_DATA,
    payload: data,
  }
}
