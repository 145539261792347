import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {ErrorMessage, Field, Form, Formik, useFormik} from 'formik'
import {IDetailform, inits} from './core/IOrganizationDetailFormModel'
import emailjs from '@emailjs/browser'
import {DetailformModel} from '../../../../models/organization_form_model'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {UserModel} from '../../../../models/user_model'
import {updateUser} from '../../../../services/userService'
import {User} from '@auth0/auth0-react'

export const OraganizationDetailsForm = () => {
  const [subName, setSubName] = useState<string>('')
  const [user, setUser] = useState<UserModel>({})
  const navigate = useNavigate()
  const [formData] = useState<IDetailform>(inits)

  const initialFormValues: IDetailform = formData

  const validationSchema = yup.object().shape({
    companyName: yup.string().required('Company Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    empCount: yup.string().required('No. Of Employees is required'),
    description: yup.string().required('Short Description is required'),
  })

  useEffect(() => {
    const retrievedSubscription = localStorage.getItem('subName')
    if (retrievedSubscription !== null) {
      setSubName(retrievedSubscription)
    }
    const storedJsonString = localStorage.getItem('user')
    if (storedJsonString !== null) {
      const retrievedObject = JSON.parse(storedJsonString)
      setUser(retrievedObject)
    }
  }, [])

  function sendEmail(values, {setSubmitting}) {
    values.userId = user.UserId
    values.subName = subName
    emailjs.send('cgaas_gmail', 'template_cgaas', values, 'g1iv3zGrHjQEOv95P').then(
      (result) => {
        setSubmitting(false)
        Swal.fire({
          text: 'We got your request. We will get back to you asap.',
          icon: 'success',
          buttonsStyling: !1,
          confirmButtonText: 'Ok Sure',
          customClass: {
            confirmButton: 'btn fw-bold btn-success',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            const updatedUser = await updateUser(user)
            if (updatedUser.subName !== '') {
              navigate('/subscriptions/confirm/pending')
            }
          }
        })
      },
      (error) => {
        setSubmitting(false)
        Swal.fire({
          text: 'Something went wrong. Please try again!',
          icon: 'warning',
          showCancelButton: !0,
          buttonsStyling: !1,
          confirmButtonText: 'Ok Sure',
          cancelButtonText: 'Close',
          customClass: {
            confirmButton: 'btn fw-bold btn-danger',
            cancelButton: 'btn fw-bold btn-active-light-primary',
          },
        })
      }
    )
  }

  return (
    <>
      {/*begin::Modal dialog*/}
      <div className='modal-dialog modal-dialog-centered mw-650px mt-15'>
        {/*begin::Modal content*/}
        <div className='modal-content'>
          {/*begin::Modal header*/}
          <div className='modal-header' id='kt_modal_create_api_key_header'>
            {/*begin::Modal title*/}
            <h2>Company details</h2>
            {/*end::Modal title*/}
          </div>
          {/*end::Modal header*/}
          <Formik
            initialValues={initialFormValues}
            onSubmit={sendEmail}
            validationSchema={validationSchema}
          >
            {({isSubmitting, values, setFieldValue, handleChange}) => (
              <Form>
                {/*begin::Form*/}
                <div id='kt_modal_create_api_key_form' className='form'>
                  {/*begin::Modal body*/}
                  <div className='modal-body py-10 px-lg-17'>
                    {/*begin::Scroll*/}
                    <div
                      className='scroll-y me-n7 px-7'
                      id='kt_modal_create_api_key_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_create_api_key_header'
                      data-kt-scroll-wrappers='#kt_modal_create_api_key_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      {/*begin::Notice*/}
                      <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6'>
                        {/*begin::Icon*/}
                        <i className='ki-outline ki-information fs-2tx text-warning me-4' />
                        {/*end::Icon*/}
                        {/*begin::Wrapper*/}
                        <div className='d-flex flex-stack flex-grow-1'>
                          {/*begin::Content*/}
                          <div className='fw-semibold'>
                            <h4 className='text-gray-900 fw-bold'>Please Note!</h4>
                            <div className='fs-6 text-gray-700'>
                              To ensure your privacy and establish your identity, we kindly request
                              the following information. Rest assured, we prioritize your trust and
                              confidentiality. Learn more about
                              <a href='#'> privacy policy.</a>
                            </div>
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Notice*/}
                      {/*begin::Input group*/}
                      <div className='mb-5 fv-row '>
                        <label className='form-label required'>Company Name</label>

                        <Field
                          name='companyName'
                          placeholder='Your Company Name'
                          className='form-control form-control-lg form-control-solid'
                          value={values.companyName}
                          onChange={handleChange}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='companyName' />
                        </div>
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='mb-5 fv-row '>
                        <label className='form-label required'>Email Address</label>

                        <Field
                          name='email'
                          placeholder='Your Email Address'
                          className='form-control form-control-lg form-control-solid'
                          value={values.email}
                          onChange={handleChange}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='email' />
                        </div>
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='d-flex flex-column mb-10 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-5 fw-semibold mb-2'>No. Of Employees</label>
                        {/*end::Label*/}
                        {/*begin::Select*/}
                        <Select
                          name='empCount'
                          instanceId='empCount-select'
                          options={[
                            {value: '2 - 10', label: '2 - 10'},
                            {value: '11 - 25', label: '11 - 25'},
                            {value: '26 - 100', label: '26 - 100'},
                            {value: '100+', label: '100+'},
                          ]}
                          value={
                            values.empCount
                              ? {value: values.empCount, label: values.empCount}
                              : null
                          }
                          components={{IndicatorSeparator: () => null}}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              padding: '0.390rem 0.7rem',
                              border: 'none',
                              borderRadius: '0.625rem',
                              backgroundColor: '#f9f9f9', // Change this to the desired color
                            }),
                          }}
                          isSearchable={false}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: '#cae2fb',
                              primary: '#140c9a',
                            },
                          })}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              'empCount',
                              selectedOption ? selectedOption.value : undefined
                            )
                          }}
                        />
                        {/*end::Select*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='d-flex flex-column mb-3 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-5 fw-semibold mb-2'>Short Description</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='description'
                          placeholder='Tell us why you need this plan'
                          style={{resize: 'none'}}
                          value={values.description}
                          onChange={handleChange}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='description' />
                        </div>
                        {/*end::Input*/}
                      </div>
                    </div>
                    {/*end::Scroll*/}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className='modal-footer flex-center mb-8'>
                    {/*begin::Button*/}
                    <button
                      type='reset'
                      id='kt_modal_create_api_key_cancel'
                      className='btn btn-light-danger me-3'
                    >
                      Back
                    </button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                    <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                      {!isSubmitting && <span className='indicator-label'>Request</span>}
                      {isSubmitting && (
                        <span className='indicatorprogress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      )}
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </div>
                {/*end::Form*/}
              </Form>
            )}
          </Formik>
        </div>
        {/*end::Modal content*/}
      </div>
    </>
  )
}
