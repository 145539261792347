import {FC} from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {VersionControlPage} from './VersionControlPage'
import { Outlet, Route, Routes } from 'react-router-dom'
import { GitHubList } from './components/github/githubList'

const versionControlBreadCrumbs: Array<PageLink> = [
  {
    title: 'CGaaS',
    path: '/versionControl',
    isSeparator: false,
    isActive: false,
  },
]

const githubControlBreadCrumbs: Array<PageLink> = [
  {
    title: 'CGaaS',
    path: '/versionControl/github',
    isSeparator: false,
    isActive: false,
  },
]
const VersionControlPageWrapper: FC = () => {
  return (
    // <>
    //   <PageTitle breadcrumbs={versionControlBreadCrumbs}>Version Control</PageTitle>
    //   <VersionControlPage />
    // </>
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={versionControlBreadCrumbs}>Version control</PageTitle>
              <VersionControlPage />
              {/* {workspaceData ? <WorkspaceList /> : <WorkspaceEmptyModal />} */}
              {/* {loading? <WorkspaceSkeleton /> : workspaceData?.length>0 ? <WorkspaceList /> : <WorkspaceEmptyModal />} */}
            </>
          }
        />
        <Route
          path='github'
          element={
            <>
              <PageTitle breadcrumbs={githubControlBreadCrumbs}>GitHub Accounts</PageTitle>
              <GitHubList />
            </>
          }
        />
       
      </Route>
    </Routes>
  )
}

export default VersionControlPageWrapper
