import React, {FC, useEffect, useRef, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {GitAccount} from '../../../../../../models/versionControl_model'
import {PropagateLoader} from 'react-spinners'
// import Select from 'react-select/dist/declarations/src/Select'
import {InputActionMeta} from 'react-select'
import Swal from 'sweetalert2'
import {
  createGitAccount,
  deleteGitAccount,
  updateGitAccount,
} from '../../../../../../services/versionControlService'

type Props = {
  initialData?: any
  onHide?: any
  updateTemporaryVersionControl: (tempGitAccount: GitAccount, action?: string) => void
}

const GitHubStepper: FC<Props> = ({initialData, onHide, updateTemporaryVersionControl}) => {
  const [isSuccessModel, setIsSuccessModel] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const {user} = useAuth0()
  const [hasError, setHasError] = useState(false)
  const [gitAccount, setGitAccount] = useState<GitAccount>()
  const [organiations, setOrganiations] = useState<string[]>([])

  // Determine if it's an edit or create mode
  useEffect(() => {
    if (initialData.gitaccountId) {
      setGitAccount(initialData)
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [initialData])

  useEffect(() => {
    if (gitAccount?.personalAccessToken) {
      searchOrganizations()
    }
  }, [gitAccount?.personalAccessToken])

  useEffect(() => {
    if (hasError) {
      Swal.fire({
        text: 'Please fill the above details',
        icon: 'warning',
        showCancelButton: !0,
        buttonsStyling: !1,
        confirmButtonText: 'Ok Sure',
        cancelButtonText: 'Close',
        customClass: {
          confirmButton: 'btn fw-bold btn-danger',
          cancelButton: 'btn fw-bold btn-active-light-primary',
        },
      })
    }
  }, [hasError])

  //update the formik values based on selected base dto
  const searchOrganizations = () => {
    if (gitAccount?.personalAccessToken) {
    }
  }

  const submit = async () => {
    if (checkValidation()) {
      setIsSubmitting(true)
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))

        if (isEditMode) {
          const updatedAuth0 = await updateGitAccount(gitAccount)
          if (updatedAuth0.operation == 'Success') {
            // setGitAccount(gitAccount)
            if (updateTemporaryVersionControl) {
              updateTemporaryVersionControl(gitAccount || {})
            }
          }
        } else {
          const userId = user?.sub
          let tempAcc: any = gitAccount
          tempAcc.userId = userId
          // tempAcc.organizationName = 'Evolza'
          // tempAcc.username = 'chamidu48'
          // tempAcc.personalAccessToken = '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'
          const createdGitAccount = await createGitAccount(tempAcc)
          if (createdGitAccount.gitaccountId !== '') {
            // setGitAccount(createdGitAccount)
            if (updateTemporaryVersionControl) {
              updateTemporaryVersionControl(createdGitAccount)
            }
            setIsSuccessModel(true)
          }
        }
      } catch (error) {
        console.error('Error creating model:', error)
        Swal.fire({
          text: 'Please add valid credentials',
          icon: 'warning',
          showCancelButton: !0,
          buttonsStyling: !1,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Close',
          customClass: {
            confirmButton: 'btn fw-bold btn-danger',
            cancelButton: 'btn fw-bold btn-active-light-primary',
          },
        })
      } finally {
        setIsSuccessModel(false)
        setIsSubmitting(false)
        onHide()
      }
    }
  }

  const checkValidation = () => {
    if (gitAccount?.nickName && gitAccount?.personalAccessToken) {
      setHasError(false)
      return true
    } else {
      setHasError(true)
      return false
    }
  }

  const handleDelete = async () => {
    Swal.fire({
      text: 'Are you sure you want to delete ' + gitAccount?.nickName + ' ?',

      icon: 'warning',
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      customClass: {
        confirmButton: 'btn fw-bold btn-danger',
        cancelButton: 'btn fw-bold btn-active-light-primary',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsDeleting(true)
        try {
          await new Promise((resolve) => setTimeout(resolve, 2000))

          const updatedAuth0 = await deleteGitAccount(gitAccount)
          if (updatedAuth0['operation'] == 'Success') {
            setIsDeleting(false)
            Swal.fire({
              icon: 'success',
              text: 'You have deleted ' + gitAccount?.nickName + '!.',
              buttonsStyling: !1,
              confirmButtonText: 'Done',
              customClass: {confirmButton: 'btn fw-bold btn-primary'},
            }).then(() => {
              // setGitAccount(gitAccount)
              if (updateTemporaryVersionControl) {
                updateTemporaryVersionControl(gitAccount || {}, 'DELETE')
              }
              setIsSuccessModel(true)
            })
          } else {
            setIsDeleting(false)
          }
        } catch (error) {
          setIsDeleting(false)
          console.error('Error creating model:', error)
          Swal.fire({
            text: 'Something went while deleting! Please try again.',
            icon: 'warning',
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Close',
            customClass: {
              confirmButton: 'btn fw-bold btn-danger',
              cancelButton: 'btn fw-bold btn-active-light-primary',
            },
          })
        } finally {
          setIsSuccessModel(false)
          setIsSubmitting(false)
          setIsDeleting(false)
          onHide()
        }
      }
    })
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='fv-row mb-9'>
          <label htmlFor='nickName' className='form-label fs-6 fw-bolder mb-3'>
            NickName
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            id='nickName'
            placeholder='Enter a nickname'
            value={gitAccount?.nickName || ''}
            onChange={(e) => {
              setGitAccount((prevAccount) => ({
                ...prevAccount,
                nickName: e.target.value,
              }))
            }}
          />
        </div>

        <div className='fv-row mb-9'>
          <label htmlFor='username' className='form-label fs-6 fw-bolder mb-3'>
            Username
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            id='username'
            placeholder='Enter username'
            value={gitAccount?.username || ''}
            onChange={(e) => {
              setGitAccount((prevAccount) => ({
                ...prevAccount,
                username: e.target.value,
              }))
            }}
          />
        </div>

        <div className='fv-row mb-9'>
          <label htmlFor='token' className='form-label fs-6 fw-bolder mb-3'>
            Personal Access Token
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            id='token'
            placeholder='Enter token'
            value={gitAccount?.personalAccessToken || ''}
            onChange={(e) => {
              setGitAccount((prevAccount) => ({
                ...prevAccount,
                personalAccessToken: e.target.value,
              }))
            }}
          />
        </div>

        {/* <div className='fv-row mb-9'>
          <label htmlFor='organization' className='form-label fs-6 fw-bolder mb-3'>
            Organization
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            id='organization'
            placeholder='Enter organization'
            value={gitAccount?.organizationName || ''}
            onChange={(e) => {}}
          />
        </div> */}

        {/* Base Input group */}
        {/* <div className='d-flex flex-column mb-10 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
            <span className=''>Base Input</span>
            <span
              className='ms-1'
              data-bs-toggle='tooltip'
              title='Specify a target object for the function you want to implement'
            >
              <i className='ki-outline ki-information-5 text-gray-500 fs-6' />
            </span>
          </label>

          <Select
            options={organiations}
            onChange={searchOrganizations}
            name='basedinput'
            placeholder='Select a base modal...'
            value={organiations.find((option) => option === gitAccount?.organizationName)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#f4f1fe',
                primary: '#140C9A',
              },
            })}
            components={{IndicatorSeparator: () => null}}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: '0.390rem 0.7rem',
                border: 'none',
                borderRadius: '0.625rem',
                backgroundColor: '#f9f9f9',
              }),
            }}
            // value={null}
            inputValue={''}
            onInputChange={function (newValue: string, actionMeta: InputActionMeta): void {
              throw new Error('Function not implemented.')
            }}
            onMenuOpen={function (): void {
              throw new Error('Function not implemented.')
            }}
            onMenuClose={function (): void {
              throw new Error('Function not implemented.')
            }}
          />
        </div> */}
      </div>
      {/*button*/}
      <div className='card-footer'>
        <div className='d-flex flex-sack justify-content-center pb-5'>
          {!isSuccessModel && !isSubmitting && (
            <div className=''>
              {isEditMode && (
                <button
                  type='button'
                  className='btn btn-lg btn-light-danger me-8'
                  onClick={handleDelete}
                  disabled = {isDeleting}
                >
                  Remove Account
                </button>
              )}
              <button disabled = {isDeleting} type='button' className='btn btn-lg btn-light-primary me-8' onClick={submit}>
                {isEditMode ? 'Reconfigure' : 'Configure'}
              </button>
            </div>
          )}
          {isSubmitting && (
            <div className='sweet-loading'>
              <div className='pb-10 pb-lg-15 text-center loading-text-gateway-config'>
                <h5 className=' text-muted fs-7 fw-semibold mb-5'>
                  Configuring Git Account
                  <span className='dot-one'> .</span>
                  <span className='dot-two'> .</span>
                  <span className='dot-three'> .</span>
                </h5>
                <PropagateLoader
                  color='#7239EA'
                  cssOverride={{}}
                  loading={true}
                  size={8}
                  speedMultiplier={0.75}
                />
              </div>
            </div>
          )}
          {isSuccessModel && (
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary me-3'
                onClick={() => {
                  setIsSubmitting(false)
                  setIsSuccessModel(false)
                  setHasError(false)
                }}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {GitHubStepper}
