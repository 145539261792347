import { AxiosResponse } from "axios";
import cgaasUsers from "./microServices/cgaas-users";
import { UserModel } from "../models/user_model";

const createUser = (user?: UserModel): Promise<UserModel>=>{
    return cgaasUsers
      .post(`CreateUser`,user)
      .then((response:AxiosResponse<UserModel>)=>response.data);
}

const updateUser = (user?: UserModel): Promise<UserModel>=>{
    return cgaasUsers
      .put(`UpdateUser?userId=${user?.UserId}`,user)
      .then((response:AxiosResponse<UserModel>)=>response.data);
}

const getUser = (userId?:string): Promise<UserModel>=>{
    return cgaasUsers
      .get(`FindUserbyUserId?userId=${userId}`)
      .then((response:AxiosResponse<UserModel>)=>response.data);
  }

const getUserbyUserId = (userId?:string): Promise<UserModel>=>{
    return cgaasUsers
      .get(`FindUserbyUserId?userId=${userId}`)
      .then((response:AxiosResponse<UserModel>)=>response.data);
  }

export {createUser, getUser, updateUser, getUserbyUserId}