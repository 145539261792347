import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export interface IVersionControl {
  datasourceId: string
  database: string
  userName: string
  password: string
  ssh: string
  credentials: string
  located: string
  url: string
  userId: string
  nickname: string
  status: string
  projectId: string
  assigned: AssignedApps[]
}

export interface IGitAccount {
  gitaccountId: string
  userId: string
  username: string
  organizationName: string
  personalAccessToken: string
  nickName: string
}

interface AssignedApps {
  workspaceName: string
  workspaceId: string
  appId: string
  appName: string
}
const createDataSourceSchemas = [
  Yup.object({
    database: Yup.string().required().label('Database Type'),
    nickname: Yup.string().required().label('Nickname'),
    accessToken: Yup.string().required().label('AccessToken'),
    organizationName: Yup.string().required().label('OrganizationName'),

  }),
  // Yup.object().shape({
  //   //url: Yup.string().required().label('Database URL'),
  //   //username: Yup.string().required().label('Username'),
  //   //password: Yup.string().required().label('Password'),
  // }),
  // Yup.object({
  //   located: Yup.string().required().label('Location Type'),
  // }),
]

const inits: IVersionControl = {
  datasourceId: '',
  database: 'MONGO',
  userName: '',
  password: '',
  ssh: '',
  credentials: '',
  located: 'CLOUD',
  url: '',
  userId: '',
  nickname: '',
  status: 'Custom',
  projectId: '',
  assigned: [],
}
const getImageUrl = (databaseType: string) => {
  switch (databaseType) {
    case 'MONGO':
      return toAbsoluteUrl('/media/db/mongodb-icon.png')
    case 'MYSQL':
      return toAbsoluteUrl('/media/db/mysql-icon.png')
    case 'FIREBASE':
      return toAbsoluteUrl('/media/db/firebase-icon.png')
    case 'POSTGRES':
      return toAbsoluteUrl('/media/db/postgresql-icon.png')
    default:
      return toAbsoluteUrl('/media/db/anyType.png')
  }
}

const getVersionImage = (versionControlType: string) => {
  switch (versionControlType) {
    case 'GitHub':
      return toAbsoluteUrl('/media/version-control/github.png')
    case 'GitLab':
      return toAbsoluteUrl('/media/version-control/gitlab.png')
    default:
      return toAbsoluteUrl('/media/version-control/github.png')
  }
}

const checkVersionAvailability = (data: any, type: string) => {
  switch (type) {
    case 'GitHub':
      if (data.gitaccountId) {
        return true
      } else return true;
    case 'GitLab':
      if (data.gitlabaccountId) {
        return false
      } else return false;
    default:
      return true
  }
}

export const getDatabaseBgColor = (databaseType: string) => {
  switch (databaseType) {
    case 'MONGO':
      return 'bg-light-success'
    case 'MYSQL':
      return 'bg-light-danger'
    case 'FIREBASE':
      return 'bg-light-warning'
    case 'POSTGRES':
      return 'bg-light-info'
    default:
      return 'bg-light-info'
  }
}

export {createDataSourceSchemas, inits, getImageUrl, getVersionImage, checkVersionAvailability}
