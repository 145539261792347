import {FC} from 'react'
import { DataSourcePage } from './DataSourcePage'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const dataSourceBreadCrumbs: Array<PageLink> = [
  {
    title: 'CGaaS',
    path: '/dataSources',
    isSeparator: false,
    isActive: false,
  },
]
const DataSourcePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={dataSourceBreadCrumbs}>Data Sources</PageTitle>
      <DataSourcePage />
      
    </>
  )
}

export default DataSourcePageWrapper
