import {AxiosResponse} from 'axios'
import {GitAccount, VersionControlConfig} from '../models/versionControl_model'
import cgaasGitVersionControl from './microServices/cgaas-git-versionControl'

//--github--
const createGitAccount = (data?: GitAccount): Promise<GitAccount> => {
  return cgaasGitVersionControl
    .post('CreateGitAccount', data)
    .then((response: AxiosResponse<GitAccount>) => response.data)
}

const updateGitAccount = (data?: GitAccount): Promise<any> => {
  return cgaasGitVersionControl
    .put('UpdateGitAccount', data)
    .then((response: AxiosResponse<any>) => response.data)
}

const deleteGitAccount = (data?: GitAccount): Promise<any> => {
  return cgaasGitVersionControl
    .delete('DeleteGitAccount' + '?userId=' + data?.userId + '&gitAccountId=' + data?.gitaccountId)
    .then((response: AxiosResponse<any>) => response.data)
}

const getAllGitAccount = (userId: string): Promise<GitAccount[]> => {
  return cgaasGitVersionControl
    .get('FindallGitAccount' + '?userId=' + userId)
    .then((response: AxiosResponse<GitAccount[]>) => response.data)
}

const getGitAccount = (userId: string, gitAccountId: string): Promise<GitAccount> => {
  return cgaasGitVersionControl
    .get('FindGitAccount' + '?userId=' + userId + '&gitAccountId=' + gitAccountId)
    .then((response: AxiosResponse<GitAccount>) => response.data)
}

const getOrganizationsByGitToken = (token: string): Promise<any> => {
  return cgaasGitVersionControl
    .get('GetOrganizations' + '?token=' + token)
    .then((response: AxiosResponse<any>) => response.data)
}

//--create config file--
const createVersionControlConfig = (data?: VersionControlConfig): Promise<any> => {
  return cgaasGitVersionControl
    .post('CreateGitOrgConfig', data)
    .then((response: AxiosResponse<any>) => response.data)
}

const updateVersionControlConfig = (data?: VersionControlConfig): Promise<any> => {
  return cgaasGitVersionControl
    .put('UpdateGitOrgConfig', data)
    .then((response: AxiosResponse<any>) => response.data)
}

const getVersionControlConfig = (
  workspaceId: string,
  userId: string
): Promise<VersionControlConfig> => {
  return cgaasGitVersionControl
    .get('GetGitOrgConfig' + '?userId=' + userId + '&workspaceId=' + workspaceId)
    .then((response: AxiosResponse<VersionControlConfig>) => response.data)
}

export {
  createGitAccount,
  createVersionControlConfig,
  updateVersionControlConfig,
  getAllGitAccount,
  deleteGitAccount,
  getVersionControlConfig,
  getGitAccount,
  getOrganizationsByGitToken,
  updateGitAccount,
}
