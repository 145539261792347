// Define action types
export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA'

// Define action creators
export const setApplicationData = (data) => {
  return {
    type: SET_APPLICATION_DATA,
    payload: data,
  }
}

