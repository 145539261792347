import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
const RequestSuccessPopup = () => {
  const {mode} = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg8-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg8.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              {/*begin::Logo*/}
              <div className='mb-7'>
                <a href='../../demo4/dist/index.html' className=''>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/CGaaS-black.png')} className='h-40px' />
                </a>
              </div>
              {/*end::Logo*/}
              {/*begin::Title*/}
              <h1 className='fw-bolder text-gray-900 mb-5'>Welcome to CGaaS</h1>
              {/*end::Title*/}
              {/*begin::Text*/}
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                This is your opportunity to get creative and make a name
                <br />
                that gives readers an idea
              </div>
              {/*end::Text*/}
              {/*begin::Illustration*/}
              <div className='mb-0'>
                <img
                  src={toAbsoluteUrl('/media/auth/welcome.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/welcome-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
              {/*end::Illustration*/}
              {/*begin::Link*/}
              <div className='mb-0'>
                <Link to='/workspaces' className='btn btn-sm btn-primary'>
                  Go to Workspaces
                </Link>
              </div>
              {/*end::Link*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RequestSuccessPopup}
