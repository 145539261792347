import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {WorkspaceModel} from '../../../../models/workspace_model'
import {RootState} from '../../../../redux-store/types'
import ReactPaginate from 'react-paginate'
import {CreateUpdateDataSourceModal} from './modals/CreateUpdateDataSource'
import {DataSourceModel} from '../../../../models/dataSource_model'
import {DataSourceCard} from '../../../../../_metronic/partials/content/cards/DataSourceCard'

export function DataSourceList({dataSourceData, updateTemporaryDataSources}) {
  // State for showing the create DataSource modal
  const [showCreateDataSourceModal, setshowCreateDataSourceModal] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  // State for search input value
  const [value, setValue] = useState('')

  // Number of items to display per page
  const itemsPerPage = 6

  // Current page number
  const [currentPage, setCurrentPage] = useState(0)

  // Calculate the total number of pages based on dataSource data
  const pageCount = Math.ceil((dataSourceData?.length || 0) / itemsPerPage)
  // Function to handle page change in pagination
  const handlePageChange = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }
  //useEffect(() => {
  //  if(dataSourceData.length==pageCount){

  //  }
  //  handlePageChange({selected: pageCount - 1})
  //}, [isDeleted])
  // Calculate the start and end index for pagination
  const startIndex = currentPage * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  return (
    <>
      {/* Header */}
      <div className='d-flex flex-wrap flex-stack mb-10'>
        <div
          className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
        >
          <h1 className='text-dark fw-bolder my-1 fs-3 lh-1'>Data Sources</h1>
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb fw-semibold fs-8 my-1'>
            <li className='breadcrumb-item text-muted'>
              <a href='../../demo4/dist/index.html' className='text-muted text-hover-primary'>
                Home
              </a>
            </li>
            <li className='breadcrumb-item text-muted'>CGaaS</li>
            <li className='breadcrumb-item text-muted'>Databases</li>
            {/*<li className='breadcrumb-item text-muted'>Wizards</li>*/}
          </ul>
          {/*end::Breadcrumb*/}
        </div>

        <div className='d-flex flex-wrap my-2'>
          {/* Search input */}
          <div className='me-4'>
            <div className='d-flex align-items-center position-relative'>
              <i className='ki-outline ki-magnifier fs-3 position-absolute ms-3'></i>
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-sm border-body bg-body w-170px ps-10'
                placeholder='Search Data Source'
                value={value}
              />
            </div>
          </div>

          {/* Create DataSource Button */}
          <CreateUpdateDataSourceModal
            show={showCreateDataSourceModal}
            handleCloseModal={() => setshowCreateDataSourceModal(false)}
            dataSourceData={dataSourceData || []}
            updateTemporaryDataSources={updateTemporaryDataSources}
          />
          <a
            href='#'
            onClick={() => setshowCreateDataSourceModal(true)}
            className='btn btn-primary btn-sm'
            data-bs-target='#kt_modal_create_dataSource'
          >
            New Data Source
          </a>
        </div>
      </div>

      {/* DataSource Cards */}
      <div className='row g-6 g-xl-9 mb-13 '>
        {dataSourceData?.slice(startIndex, endIndex)?.map((dataSource: DataSourceModel) => (
          <div className='col-md-6 col-xl-4 ' key={dataSource.datasourceId}>
            <DataSourceCard
              dataSourceData={dataSource || {}}
              updateTemporaryDataSources={updateTemporaryDataSources}
              onDelete = {setIsDeleted}
            />
          </div>
        ))}
      </div>

      {/* Pagination */}
      {dataSourceData?.length && dataSourceData?.length > 6 && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          pageCount={pageCount}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination justify-content-center'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
        />
      )}
    </>
  )
}
