export interface IDetailform {
  companyName: string
  email: string
  empCount: string
  description: string
}

const inits: IDetailform = {
  companyName: '',
  email: '',
  empCount: '',
  description: ''
}

export {inits}