/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {VerionControlList} from './components/VersionControlList'
import {GitAccount} from '../../../models/versionControl_model'
import {getAllGitAccount, getGitAccount} from '../../../services/versionControlService'
import { VersionControlSkeleton } from '../../loading_skeleton/version_control_skeleton'

const VersionControlPage: React.FC = () => {
  const {user} = useAuth0()
  const [loading, setLoading] = useState(true)

  // State to store filtered workspace data
  const [gitAccountData, setGitAccountData] = useState<GitAccount[]>([])

  //--gitlab--
  const [gitLabAccountData, setGitLabAccountData] = useState<Array<GitAccount>>([])
  //--otherssss--

  useEffect(() => {
    // Fetch the git account when the component mounts
    const userId = user?.sub
    getAllGitAccount(userId || "")
      .then((data) => {
        setGitAccountData(data)
        setLoading(false) // Set loading to false after data is received
      })
      .catch((error) => {
        // Handle errors if needed
        console.error('Error fetching datasources:', error)
        setLoading(false) // Set loading to false on error
      })
  }, [])

  //update data in the primary component without any API calls (CREATE, UPDATE, DELETE)
  const updateTemporaryDataSources = (tempGitAccount: GitAccount, action: String) => {
    setGitAccountData((prevData) => {
      return [...prevData, tempGitAccount]


    // If the action is DELETE, remove the data source
    // if (action === 'DELETE') {
    //   return prevData.filter(
    //     (gitAccount) => gitAccount.gitaccountId !== tempGitAccount.gitaccountId
    //   )
    // }
    // Find the index of the application to update, if it exists
    // const index = prevData?.findIndex(
    //   (dataSource) => dataSource.gitaccountId === tempGitAccount.gitaccountId
    // )
    // if (index !== -1) {
    //   // If the application already exists in the array, update it
    //   const updatedApplications = [...prevData]
    //   updatedApplications[index] = tempGitAccount
    //   return updatedApplications
    // } else {
      // Create a new array by adding the tempDataSource to the existing data
      // return [...prevData, tempGitAccount]
    // }
    })
  }

  return (
    <>
      {loading ? (
        <VersionControlSkeleton />
      ) : (
        <VerionControlList
          gitAccounts={gitAccountData || undefined}
          updateTemporaryGitAccount={updateTemporaryDataSources}
        />
      )}
    </>
  )
}

export {VersionControlPage}
