import React, {useState} from 'react'
import {CreateUpdateDataSourceModal} from './modals/CreateUpdateDataSource'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

export const DataSourceEmptyModal = () => {
  
  // State for showing the create DataSource modal
  const [showCreateDataSourceModal, setshowCreateDataSourceModal] = useState<boolean>(false)

  return (
    <div className='card mb-10'>
      <div className='card-body d-flex align-items-center py-8 content d-flex flex-column flex-column-fluid'>
        <div className='card-px text-center pt-15 pb-15'>
          <h2 className='fs-2x fw-bold mb-0'>Create a New Data Source</h2>
          <p className='text-gray-400 fs-4 fw-semibold py-7'>
            Click on the below button to
            <br />
            create a data source.
          </p>

          <a
            href='#'
            onClick={() => setshowCreateDataSourceModal(true)}
            className='btn btn-primary er fs-6 px-8 py-4'
            data-bs-target='#kt_modal_create_dataSource'
          >
            Create Data Source
          </a>

          {/* CreateUpdateDataSourceModal */}
          <CreateUpdateDataSourceModal
            show={showCreateDataSourceModal}
            handleCloseModal={() => setshowCreateDataSourceModal(false)}
          />
        </div>

        <div className='text-center pb-15 px-5'>
          <img
          src={toAbsoluteUrl("/media/illustrations/dozzy-1/5.png")}
            alt=''
            className='mw-100 h-200px h-sm-325px'
          />
        </div>
      </div>
    </div>
  )
}
