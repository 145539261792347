/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useLayout, ILayout, getLayout, LayoutSetup} from '../../../../_metronic/layout/core'
import {DataSourceCard} from '../../../../_metronic/partials/content/cards/DataSourceCard'
import {CreateUpdateDataSourceModal} from './components/modals/CreateUpdateDataSource'
import {Horizontal} from '../../wizards/components/Horizontal'
import {Card3} from '../../../../_metronic/partials/content/cards/Card3'
import {DataSourceModel} from '../../../models/dataSource_model'
import {useAuth0} from '@auth0/auth0-react'
import {getAllDataSourcesByUserId} from '../../../services/dataSourceService'
import {DataSourceList} from './components/DataSourceList'
import {DataSourceEmptyModal} from './components/DataSourceEmptyModal'
import { DataSourceSkeleton } from '../../loading_skeleton/datasource_skeleton'

const DataSourcePage: React.FC = () => {
  const {user} = useAuth0()
  const [loading, setLoading] = useState(true)

  // State to store filtered workspace data
  const [dataSourceData, setDataSourceData] = useState<Array<DataSourceModel>>([])

  useEffect(() => {
    // Fetch the datasources when the component mounts
    const userId = user?.sub
    getAllDataSourcesByUserId(userId || '')
      .then((data) => {
        setDataSourceData(data)
        setLoading(false) // Set loading to false after data is received
      })
      .catch((error) => {
        // Handle errors if needed
        console.error('Error fetching datasources:', error)
        setLoading(false) // Set loading to false on error
      })
  }, [])

  //update data in the primary component without any API calls (CREATE, UPDATE, DELETE)
  const updateTemporaryDataSources = (tempDataSource: DataSourceModel, action: String) => {
    setDataSourceData((prevData) => {
      // If the action is DELETE, remove the data source
      if (action === 'DELETE') {
        return prevData.filter(
          (dataSource) => dataSource.datasourceId !== tempDataSource.datasourceId
        )
      }

      // Find the index of the application to update, if it exists
      const index = prevData?.findIndex(
        (dataSource) => dataSource.datasourceId === tempDataSource.datasourceId
      )

      if (index !== -1) {
        // If the application already exists in the array, update it
        const updatedApplications = [...prevData]
        updatedApplications[index] = tempDataSource
        return updatedApplications
      } else {
        // Create a new array by adding the tempDataSource to the existing data
        return [...prevData, tempDataSource]
      }
    })
  }

  return (
    <>
    {loading ? <DataSourceSkeleton/>: dataSourceData?.length>0 ? (
        <DataSourceList
          dataSourceData={dataSourceData || []}
          updateTemporaryDataSources={updateTemporaryDataSources}
        />
      ) : (
        <DataSourceEmptyModal />
      )}
    </>
  )
}

export {DataSourcePage}
