import React, { createContext, useContext, useState } from 'react';

interface LoadingContextType {
  isGLoading: boolean;
  setGLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export function LoadingProvider({ children }) {
  const [isGLoading, setGLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ isGLoading, setGLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useGLoading(): LoadingContextType {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
}
