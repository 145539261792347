import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {SubscriptionPage} from './SubscriptionPage'
import {useAuth0} from '@auth0/auth0-react'
import {createUser, getUser, getUserbyUserId, updateUser} from '../../../services/userService'
import {getSubscriptionBySubId} from '../../../services/subscriptionService'
import {UserModel} from '../../../models/user_model'
import {v4 as uuidv4} from 'uuid'
import SplashScreenComponent from '../../../../_metronic/assets/ts/components/_SplashScreenComponent'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import {SubscriptionConfirmation} from './organization-detail-form/SubscriptionConfirmation'
import {createDefaultDataSource, findUserDefaultSettings} from '../../../services/dataSourceService'
import {GitAccount} from '../../../models/versionControl_model'
import {createGitAccount} from '../../../services/versionControlService'
import {
  checkCbfg,
  checkCgaas,
  checkFgai,
  checkGateway,
  checkManifest,
  checkUIGen,
  checkUsers,
} from '../../../services/health-check/healthCheckService'

const SubscriptionPageWrapper: FC = () => {
  const {isAuthenticated, user} = useAuth0()
  const [loginSuccess, setLoginSuccess] = useState<boolean>()
  const [isPermissionAllowed, setIsPermissionAllowed] = useState<boolean>()
  const [userData, setUserData] = useState<UserModel>({})
  const [isLoading, setIsLoading] = useState(true)
  const {logout} = useAuth0()
  const navigate = useNavigate()

  async function cbfgCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkCbfg()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function manifestCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkManifest()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function UIGenCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkUIGen()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function fgaiCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkFgai()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function cgaasGenCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkCgaas()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function usersCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkUsers()
    } catch (error: any) {
      //--route to error page--
    }
  }

  async function gatewayMgtCheck() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const result = await checkGateway()
    } catch (error: any) {
      //--route to error page--
    }
  }

  // useEffect(() => {
  //   const userId = user?.sub
  //   let newUser: UserModel = userData
  //   newUser.objectId = uuidv4()
  //   newUser.UserId = userId
  //   newUser.email = user?.email
  //   newUser.permission = false
  //   newUser.isSubscribed = false
  //   newUser.name = user?.nickname

  //   // async function fetchData() {
  //   //   if (user?.firstLogin) {
  //   //     try {
  //   //       setIsLoading(false)
  //   //       await new Promise((resolve) => setTimeout(resolve, 2000))
  //   //       const createdUser = await createUser(newUser)
  //   //       if (createdUser.UserId !== '') {

  //   //         //--default gitaccount
  //   //         let tempAcc: GitAccount = {
  //   //           nickName: 'GIT-DEFAULT',
  //   //           personalAccessToken: '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',
  //   //           username: 'cgassUser',
  //   //           userId: userId,
  //   //         }

  //   //         const createdGitAccount = await createGitAccount(tempAcc)
  //   //         if (createdGitAccount.gitaccountId) {

  //   //           //--default datasource--
  //   //           const defaultdataSource = await createDefaultDataSource(userId || '')
  //   //           if (defaultdataSource['operation'] == 'Success') {
  //   //             setUserData(createdUser)
  //   //             setLoginSuccess(false)
  //   //             setIsLoading(false)
  //   //           }
  //   //         }
  //   //       }
  //   //     } catch (error: any) {
  //   //       logout({
  //   //         logoutParams: {
  //   //           returnTo: window.location.origin + '/cgaas',
  //   //         },
  //   //       })
  //   //     }
  //   //   }
  //   //   if (user?.firstLogin === false) {
  //   //     try {
  //   //       await new Promise((resolve) => setTimeout(resolve, 2000))
  //   //       const user = await getUser(userId)
  //   //       if (user) {
  //   //         if (user[0].permission === true) {
  //   //           await new Promise((resolve) => setTimeout(resolve, 2000))
  //   //           const subscription = await getSubscriptionBySubId(user[0].subId)
  //   //           if (subscription?.subId !== '') {
  //   //             if (subscription.limitWS && subscription.limitApp) {
  //   //               localStorage.setItem('limitWS', subscription.limitWS)
  //   //               localStorage.setItem('limitAPP', subscription.limitApp)
  //   //             }
  //   //             setLoginSuccess(true)
  //   //             setIsPermissionAllowed(true)
  //   //           }
  //   //           setIsLoading(false)
  //   //         } else {
  //   //           setIsPermissionAllowed(false)
  //   //           setLoginSuccess(true)
  //   //           setIsLoading(false)
  //   //         }
  //   //       }
  //   //     } catch (error: any) {
  //   //       logout({
  //   //         logoutParams: {
  //   //           returnTo: window.location.origin + '/cgaas',
  //   //         },
  //   //       })
  //   //     }
  //   //   }
  //   // }
  //   // fetchData()

  //   async function fetchData() {
  //     try {
  //       await new Promise((resolve) => setTimeout(resolve, 2000))
  //       const user = await getUserbyUserId(userId)
  //       if (user === null) {
  //         setIsLoading(false)
  //         await new Promise((resolve) => setTimeout(resolve, 2000))
  //         const createdUser = await createUser(newUser)
  //         if (createdUser.UserId !== '') {
  //           setUserData(createdUser)
  //           //--default gitaccount
  //           let tempAcc: GitAccount = {
  //             nickName: 'GIT-DEFAULT',
  //             personalAccessToken: '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',
  //             username: 'cgassUser',
  //             userId: userId,
  //           }

  //           const createdGitAccount = await createGitAccount(tempAcc)
  //           if (createdGitAccount.gitaccountId) {
  //             //--default datasource--
  //             const defaultdataSource = await createDefaultDataSource(userId || '')
  //             if (defaultdataSource['operation'] == 'Success') {
  //               setLoginSuccess(false)
  //               setIsLoading(false)
  //             }
  //           }
  //         }
  //       }
  //       if (user !== null) {
  //         setUserData(user)
  //         if (user.isSubscribed) {
  //           if (user.permission === true) {
  //             await new Promise((resolve) => setTimeout(resolve, 2000))
  //             const subscription = await getSubscriptionBySubId(user.subId)
  //             if (subscription?.subId !== '') {
  //               if (subscription.limitWS && subscription.limitApp) {
  //                 localStorage.setItem('limitWS', subscription.limitWS)
  //                 localStorage.setItem('limitAPP', subscription.limitApp)
  //               }
  //               setLoginSuccess(true)
  //               setIsPermissionAllowed(true)
  //             }
  //             setIsLoading(false)
  //           } else {
  //             setIsPermissionAllowed(false)
  //             setLoginSuccess(true)
  //             setIsLoading(false)
  //           }
  //         }
  //       }
  //     } catch (error: any) {
  //       // logout({
  //       //   logoutParams: {
  //       //     returnTo: window.location.origin + '/cgaas',
  //       //   },
  //       // })
  //     }
  //   }
  //   fetchData()
  // }, [])

  //--check user acvtive or first login--
  // useEffect(() => {

  //   const userId = user?.sub
  //   setIsLoading(true)

  //   async function fetchData() {
  //     try {
  //       await new Promise((resolve) => setTimeout(resolve, 2000))
  //       const user = await getUserbyUserId(userId)
  //       if (user !== null) {
  //         setUserData(user)

  //         //--user's first login?--
  //         if (user.isFirstLogin) {
  //           //--default gitaccount
  //           let tempAcc: GitAccount = {
  //             nickName: 'GIT-DEFAULT',
  //             personalAccessToken: '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',
  //             username: 'cgassUser',
  //             userId: userId,
  //           }

  //           const createdGitAccount = await createGitAccount(tempAcc)
  //           if (createdGitAccount.gitaccountId) {
  //             //--default datasource--
  //             const defaultdataSource = await createDefaultDataSource(userId || '')

  //             if (defaultdataSource['operation'] == 'Success') {

  //               //--update user 1st login false--
  //               user.isFirstLogin = false
  //               const updatedUser = await updateUser(user)
  //               if (updateUser['operation'] == 'success') {
  //                 setLoginSuccess(false)
  //                 setIsLoading(false)
  //               }
  //             } else {
  //               console.log('datasource creation failed')
  //             }
  //           } else {
  //             console.log('git acc creation failed')
  //           }
  //         } else {
  //           console.log('not 1st login')
  //           //--not first login--
  //         }

  //         if (user.isSubscribed && user.subId) {
  //           console.log('user has subscribed')
  //           //--user has subscribed--
  //           if (user.permission === true) {
  //             await new Promise((resolve) => setTimeout(resolve, 2000))
  //             const subscription = await getSubscriptionBySubId(user.subId)
  //             if (subscription?.subId !== '') {
  //               if (subscription.limitWS && subscription.limitApp) {
  //                 localStorage.setItem('limitWS', subscription.limitWS)
  //                 localStorage.setItem('limitAPP', subscription.limitApp)
  //               }
  //               setLoginSuccess(true)
  //               setIsPermissionAllowed(true)
  //             }
  //             setIsLoading(false)
  //           } else {
  //             setIsPermissionAllowed(false)
  //             setLoginSuccess(false)
  //             setIsLoading(false)
  //           }
  //         } else {
  //           console.log("user hasnn't subscribed")
  //           //--user hasn't subscribed--
  //           setIsPermissionAllowed(false)
  //           setLoginSuccess(false)
  //           setIsLoading(false)
  //         }
  //       } else {
  //         console.log('user not available')
  //         setIsPermissionAllowed(false)
  //         setLoginSuccess(false)
  //         setIsLoading(false)
  //       }
  //     } catch (error: any) {
  //       // logout({
  //       //   logoutParams: {
  //       //     returnTo: window.location.origin + '/cgaas',
  //       //   },
  //       // })
  //     }
  //   }

  //   fetchData()
  // }, [])

  useEffect(() => {
    const userId = user?.sub
    setIsLoading(true)

    async function fetchData() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        const user = await getUserbyUserId(userId)
        if (user !== null) {
          setUserData(user)

          findUserDefaultSettings(user.UserId)
            .then((result) => {
              console.log('Default settings found:', result)
            })
            .catch((error) => {
              navigate('/error')
            })

          if (user.isSubscribed && user.subId) {
            console.log('user has subscribed')
            //--user has subscribed--
            if (user.permission === true) {
              await new Promise((resolve) => setTimeout(resolve, 2000))
              const subscription = await getSubscriptionBySubId(user.subId)
              if (subscription?.subId !== '') {
                if (subscription.limitWS && subscription.limitApp) {
                  localStorage.setItem('limitWS', subscription.limitWS)
                  localStorage.setItem('limitAPP', subscription.limitApp)
                }
                setLoginSuccess(true)
                setIsPermissionAllowed(true)
              }
              setIsLoading(false)
            } else {
              setIsPermissionAllowed(false)
              setLoginSuccess(false)
              setIsLoading(false)
            }
          } else {
            console.log("user hasnn't subscribed")
            //--user hasn't subscribed--
            setIsPermissionAllowed(false)
            setLoginSuccess(false)
            setIsLoading(false)
          }
        } else {
          console.log('user not available')
          setIsPermissionAllowed(false)
          setLoginSuccess(false)
          setIsLoading(false)
        }
      } catch (error: any) {
        // logout({
        //   logoutParams: {
        //     returnTo: window.location.origin + '/cgaas',
        //   },
        // })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (loginSuccess) {
      // fgaiCheck();
      // UIGenCheck();
      // cbfgCheck();
      // usersCheck();
      // manifestCheck();
      // cgaasGenCheck();
      // gatewayMgtCheck();
      // console.log('hayyooooooo hemanennwa')
    }
  }, [loginSuccess])

  if (isLoading) {
    return (
      <div>
        <SplashScreenComponent />
      </div>
    )
  }

  return (
    <Routes>
      {/* {!loginSuccess && !isLoading && (
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={[]}>Subscription</PageTitle>
              <SubscriptionPage user={userData} />
            </>
          }
        />
      )} */}
      {!isPermissionAllowed && !loginSuccess && !isLoading && (
        <Route index element={<Navigate to='/error' />} />
      )}
      {isPermissionAllowed && loginSuccess && !isLoading && (
        <Route index element={<Navigate to='/workspaces' />} />
      )}
    </Routes>
  )
}

export default SubscriptionPageWrapper
