import { AxiosResponse } from "axios";
import cgaasUsers from "./microServices/cgaas-users";
import { SubscriptionModel } from "../models/users_model";

const getAllSubscriptions = (): Promise<SubscriptionModel[]>=>{
    return cgaasUsers
      .get('FindallSubscriptions')
      .then((response:AxiosResponse<SubscriptionModel[]>)=>response.data);
  }

const getSubscriptionBySubId = (subId?:string): Promise<SubscriptionModel>=>{
    return cgaasUsers
      .get(`FindSubscriptionsBySubId?subId=${subId}`)
      .then((response:AxiosResponse<SubscriptionModel>)=>response.data);
  }

export {getAllSubscriptions, getSubscriptionBySubId}