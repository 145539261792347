import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useAuth0} from '@auth0/auth0-react'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
const RequestedPopup = () => {
  const {logout} = useAuth0()
  const {mode} = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              {/*begin::Logo*/}
              <div className='mb-7'>
                <a href='../../demo4/dist/index.html' className=''>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/CGaaS-black.png')}
                    className='h-40px'
                  />
                </a>
              </div>
              {/*end::Logo*/}
              {/*begin::Title*/}
              <h1 className='fw-bolder text-gray-900 mb-5'>Your request is still processing</h1>
              {/*end::Title*/}
              {/*begin::Text*/}
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                This is your opportunity to get creative and make a name
                <br />
                that gives readers an idea
              </div>
              {/*end::Text*/}
              {/*begin::Illustration*/}
              <div className='mb-0'>
                <img
                  src={toAbsoluteUrl('/media/auth/membership.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/membership-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
              {/*end::Illustration*/}
              {/*begin::Link*/}
              <div className='mb-0'>
                {/* <Link to='/workspaces' className='btn btn-sm btn-primary'>
                  Go to Workspaces
                </Link> */}
                <button
                  type='button'
                  className='btn btn-icon btn-primary btn-active-light m-3 '
                  style={{width: '10rem'}}
                  title='Logout'
                  onClick={() => {
                    logout({
                      logoutParams: {
                        returnTo: window.location.origin + '/cgaas',
                      },
                    })
                  }}
                >
                  {/* <KTIcon iconName='element-11' className='fs-2 text-lg-1' /> */}
                  <i className='ki-duotone ki-exit-left fs-2x me-2'>
                    <i className='path1'></i>
                    <i className='path2'></i>
                  </i>
                  Log Out
                </button>
              </div>
              {/*end::Link*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RequestedPopup}
