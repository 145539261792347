import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"

export interface IDeployment {
    deploymentId?: string
    name?: string
    type?: string
  }

const getDeploymentImage = (versionControlType: string) => {
    switch (versionControlType) {
      case 'AWS':
        return toAbsoluteUrl('/media/deployment/aws.webp')
      case 'GCLOUD':
        return toAbsoluteUrl('/media/deployment/gcloud.png')
        case 'AKAMAI':
        return toAbsoluteUrl('/media/deployment/akamai.png')
        case 'DIGITAL-OCEAN':
        return toAbsoluteUrl('/media/deployment/digitalocean.png')
      default:
        return toAbsoluteUrl('/media/deployment/sample.jpeg')
    }
  }


const checkVersionAvailability = (data: any, type: string) => {
  switch (type) {
    case 'AWS':
      if (data.deploymentId) {
        return true
      } else return true;
    case 'GCLOUD':
      if (data.deploymentId) {
        return false
      } else return false;
    default:
      return true
  }
}

export {getDeploymentImage, checkVersionAvailability}
