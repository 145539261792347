import {createPortal} from 'react-dom'
import {Form, Modal} from 'react-bootstrap'
import {DataSourceModel} from '../../../../../models/dataSource_model'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {GitHubStepper} from './configs/GitHubStepper'
import {GitAccount} from '../../../../../models/versionControl_model'

type Props = {
  show: boolean
  handleCloseModal: () => void
  initialData?: GitAccount
  versionControlData?: GitAccount
  updateTemporaryVersionControl: (tempGitAccount: GitAccount, action?: string) => void
  type: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateUpdateVersionControlModal = ({
  show,
  handleCloseModal,
  initialData,
  updateTemporaryVersionControl,
  versionControlData,
  type,
}: Props) => {
  // Close the modal and reset states
  const handleClose = () => {
    handleCloseModal()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_dataSource'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-850px '
      backdrop='static'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header'>
        <h2>Add GitHub Account</h2>
        {/* Close button */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <GitHubStepper
          initialData={initialData}
          onHide={handleClose}
          updateTemporaryVersionControl={updateTemporaryVersionControl}
        />
    </Modal>,
    modalsRoot
  )
}

export {CreateUpdateVersionControlModal}
