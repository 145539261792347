import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {CreateUpdateWorkspaceModal} from '../apps/workspace/components/modals/CreateUpdateWorkspace'
import {useState} from 'react'
import {CreateUpdateDataSourceModal} from '../apps/dataSources/components/modals/CreateUpdateDataSource'

const DeploymentControlSkeleton = () => {
  // State for showing the create DataSource modal
  const [showCreateDataSourceModal, setshowCreateDataSourceModal] = useState<boolean>(false)

  return (
    <>
      {/* Header */}
      <div className='d-flex flex-wrap flex-stack mb-10'>
        <div
          className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
        >
          <h1 className='text-dark fw-bolder my-1 fs-3 lh-1'>Deployment Configurations</h1>
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb fw-semibold fs-8 my-1'>
            <li className='breadcrumb-item text-muted'>
              <a href='../../demo4/dist/index.html' className='text-muted text-hover-primary'>
                Home
              </a>
            </li>
            <li className='breadcrumb-item text-muted'>CGaaS</li>
            <li className='breadcrumb-item text-muted'>Deployments</li>
          </ul>
          {/*end::Breadcrumb*/}
        </div>

        <div className='d-flex flex-wrap my-2'>
          {/* Search input */}
          <div className='me-4'>
            <div className='d-flex align-items-center position-relative'>
              <i className='ki-outline ki-magnifier fs-3 position-absolute ms-3'></i>
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-sm border-body bg-body w-170px ps-10'
                placeholder='Search Data Source'
              />
            </div>
          </div>

          {/* Create DataSource Button */}
          <CreateUpdateDataSourceModal
            show={showCreateDataSourceModal}
            handleCloseModal={() => setshowCreateDataSourceModal(false)}
            dataSourceData={[]}
          />
          <a
            href='#'
            className='btn btn-primary btn-sm'
            data-bs-target='#kt_modal_create_dataSource'
          >
            New Deploymemnt Config
          </a>
        </div>
      </div>
      <div className='row g-8 g-xl-10'>
        {[1, 2, 3, 4, 5, 6].map((n, index) => {
          return (
              <div className='col-md-6 col-xl-4 ' key={index}>
                <div className='card border border-2 border-gray-300 border-hover'>
                  <div className='card-body flex-column justify-content-between mb-5'>
                    {/* header */}
                    <div className='d-flex align-items-center justify-content-center mb-7'>
                      <Skeleton circle={true} height={80} width={80} />
                    </div>
                    <div className='d-flex justify-content-center mb-7'>
                    <Skeleton height={25} width={50} />
                    </div>
                    <div className='d-flex justify-content-center mb-5'>
                    <Skeleton height={25} width={100} />
                    </div>

                    
                  </div>
                </div>
              </div>
          )
        })}
      </div>
    </>
  )
}
export {DeploymentControlSkeleton}
