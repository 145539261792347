/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  getDatabaseBgColor,
  getImageUrl,
} from '../../../../app/modules/apps/dataSources/core/IDataSourceModels'
import {CreateUpdateDataSourceModal} from '../../../../app/modules/apps/dataSources/components/modals/CreateUpdateDataSource'
import {DataSourceModel} from '../../../../app/models/dataSource_model'
import Swal from 'sweetalert2'
import {deleteDataSource} from '../../../../app/services/dataSourceService'
import { ViewDataSourceModal } from '../../../../app/modules/apps/dataSources/components/modals/ViewDataSource'

type Props = {
  dataSourceData: DataSourceModel
  updateTemporaryDataSources:any
  onDelete:any
}

const DataSourceCard: FC<Props> = ({dataSourceData,updateTemporaryDataSources,onDelete}) => {
  // State for showing the edit DataSource modal
  const [showEditDataSourceModal, setshowEditDataSourceModal] = useState<boolean>(false)
  const [showViewDatSource, setShowViewDatSource] = useState<boolean>(false)

  // Function to handle the action change
  const handleActionChange = (e) => {
    if (e.target.value === 'EDIT') {
      // When "Edit" option is selected
      setshowEditDataSourceModal(true)
    } else if (e.target.value === 'DELETE') {
      // When "Delete" option is selected
      handleDelete(dataSourceData)
    }

    // Reset the select input to its default value
    e.target.value = 'Action'
  }

  const handleDelete = (dataSource: DataSourceModel) => {
    Swal.fire({
      text: 'Are you sure you want to delete ' + dataSource.nickname + ' ?',

      icon: 'warning',
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      customClass: {
        confirmButton: 'btn fw-bold btn-danger',
        cancelButton: 'btn fw-bold btn-active-light-primary',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deletedApplication = await deleteDataSource(dataSource)
        if (deletedApplication['operation'] == 'Success') {
          //temporary update the data source array in the data source list 
          if (updateTemporaryDataSources) {
            updateTemporaryDataSources(dataSource,"DELETE");
            onDelete(true)
          }

          Swal.fire({
            icon: 'success',
            text: 'You have deleted ' + dataSource.nickname + '!.',
            buttonsStyling: !1,
            confirmButtonText: 'Ok, got it!',
            customClass: {confirmButton: 'btn fw-bold btn-primary'},
          })
        } else {
          Swal.fire('Error!', 'There was an error while deleting the datasource.', 'error')
        }
      }
    })
  }

  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            <img
              alt='Pic'
              className={`${getDatabaseBgColor(dataSourceData.database || 'anyType')} p-5`}
              src={getImageUrl(dataSourceData.database || 'anyType')}
            />
          {!dataSourceData.assigned.length==null && <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>}
          </div>
        </div>

        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
          {dataSourceData.nickname}
        </a>

        <div className='fw-bold text-gray-400 mb-6'>{dataSourceData.status || 'Default'}</div>

        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-2 mx-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{dataSourceData.located}</div>
            <div className='fw-bold text-gray-400'>Location</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{dataSourceData.database}</div>
            <div className='fw-bold text-gray-400'>Database</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{dataSourceData.assigned==null?"0":dataSourceData.assigned.length}</div>
            <div className='fw-bold text-gray-400'>Usages</div>
          </div>
        </div>
        {showEditDataSourceModal && (
          <CreateUpdateDataSourceModal
            show={showEditDataSourceModal}
            handleCloseModal={() => setshowEditDataSourceModal(false)}
            initialData={dataSourceData}
            updateTemporaryDataSources={updateTemporaryDataSources}
          />
        )}
        <ViewDataSourceModal
        show={showViewDatSource}
        handleCloseModal={()=>{setShowViewDatSource(false)}}
        dataSourceData={dataSourceData}
        />
        <div className='d-flex flex-center  mb-5'>
          <a href='#' className='btn btn-sm btn-light me-4' 
          onClick={()=>{
            setShowViewDatSource(true)
          }}>
            <KTIcon iconName='eye' className='fs-3'/>
            View
          </a>
          { dataSourceData.status!=='Default' && <a className=''>
            <select
              className='form-select btn pe-12 btn-sm btn-light form-select-solid'
              aria-label='Select Action'
              onChange={handleActionChange}
            >
              <option>Action</option>
              <option value='EDIT'>Edit</option>
              <option value='DELETE'>Delete</option>
            </select>
          </a>}
        </div>
      </div>
    </div>
  )
}

export {DataSourceCard}
