/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useAuth0} from '@auth0/auth0-react'

import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, useAuth} from '../modules/auth'
import {App} from '../App'
import {AuthPage} from '../modules/auth/AuthPage'
import SplashScreenComponent from '../../_metronic/assets/ts/components/_SplashScreenComponent'
import {RootState} from '../redux-store/types'
import {createUser, getUser} from '../services/userService'
import { UserModel } from '../models/user_model'
import { getSubscriptionBySubId } from '../services/subscriptionService'
import { v4 as uuidv4 } from 'uuid';
import { useGLoading } from '../../_metronic/layout/core/LoadingProvider'

const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const {isAuthenticated, isLoading, user} = useAuth0()
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [userData, setUserData] = useState<UserModel>({})
  const {setGLoading}=useGLoading()

// useEffect(() => {
//   const userId = user?.sub
//   let newUser : UserModel = userData
//   newUser.objectId = uuidv4()
//   newUser.UserId = userId
//   newUser.email = user?.email
//   newUser.permission = false
//   newUser.name = user?.name

//   async function fetchData() {
//     if (user?.firstLogin) {
//       await new Promise((resolve) => setTimeout(resolve, 2000))
//       const createdUser = await createUser(newUser)
//       if (createdUser['operation'] === 'Success') {
//         setLoginSuccess(true)
//       }
//     }
//     if(user?.firstLogin === false){
//       await new Promise((resolve) => setTimeout(resolve, 2000))
//       const user = await getUser(userId)
//       if (user.permission === true) {
//         await new Promise((resolve) => setTimeout(resolve, 2000))
//         const subscription = await getSubscriptionBySubId(user.subId)
//         if (subscription?.subId !== '') {
//           console.log("subId", subscription.subId)
//         }
//         setLoginSuccess(true)
//       }
//       else{
//         console.log("no sub Id")
//         setLoginSuccess(true)
//       }
//     }
//   }
//   fetchData()
// }, [isAuthenticated])

  if (isLoading) {

    //setGLoading(true)
    return (
      <div>
        <SplashScreenComponent />
      </div>
    )
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      {/*the global loading screen component*/}
      {/* <SplashScreenComponent /> */}

      {/*page routes */}
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {/* {loginSuccess && <Route index element={<Navigate to='/workspaces' />} />}
              {!loginSuccess && ( */}
                <Route index element={<Navigate to='/subscriptions' />} />
              {/* )} */}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}