import {FC} from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { DeploymentPage } from './DeploymentPage'
import { Outlet, Route, Routes } from 'react-router-dom'
import { AwsList } from './components/aws-list/awsList'
import { GCloudList } from './components/gcloud-list/gcloudList'
import { AkamaiList } from './components/akamai-list/akamaiList'
import { DigitalOceanList } from './components/digitalocean-list/digitaloceanList'

const deploymentBreadCrumbs: Array<PageLink> = [
  {
    title: 'CGaaS',
    path: '/deployments',
    isSeparator: false,
    isActive: false,
  },
]
const DeploymentPageWrapper: FC = () => {
    return (
        // <>
        //   <PageTitle breadcrumbs={versionControlBreadCrumbs}>Version Control</PageTitle>
        //   <VersionControlPage />
        // </>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path=''
              element={
                <>
                  <PageTitle breadcrumbs={deploymentBreadCrumbs}>Deployment configurations</PageTitle>
                  <DeploymentPage />
                  {/* {workspaceData ? <WorkspaceList /> : <WorkspaceEmptyModal />} */}
                  {/* {loading? <WorkspaceSkeleton /> : workspaceData?.length>0 ? <WorkspaceList /> : <WorkspaceEmptyModal />} */}
                </>
              }
            />
            <Route
              path='aws'
              element={
                <>
                  <PageTitle breadcrumbs={deploymentBreadCrumbs}>AWS Accounts</PageTitle>
                  <AwsList />
                </>
              }
            />
            <Route
              path='gcloud'
              element={
                <>
                  <PageTitle breadcrumbs={deploymentBreadCrumbs}>GCLOUD Accounts</PageTitle>
                  <GCloudList />
                </>
              }
            />
            <Route
              path='akamai'
              element={
                <>
                  <PageTitle breadcrumbs={deploymentBreadCrumbs}>GCLOUD Accounts</PageTitle>
                  <AkamaiList />
                </>
              }
            /><Route
            path='digital-ocean'
            element={
              <>
                <PageTitle breadcrumbs={deploymentBreadCrumbs}>GCLOUD Accounts</PageTitle>
                <DigitalOceanList />
              </>
            }
          />
           
          </Route>
        </Routes>
      )
}

export default DeploymentPageWrapper
