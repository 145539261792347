/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ErrorMessage, Field} from 'formik'
import {KTIcon, useDbPath} from '../../../../../../../_metronic/helpers'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Choose Database Type
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='col-lg-12 mb-12'>
        <div className='fv-row'>
          {/*begin:Option */}
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-success'>
                  <img src={useDbPath('mongodb-icon.png')} alt='' className='mh-40px p-2' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>MongoDB</span>
                <span className='fs-6 fw-bold text-gray-400'>NoSQL database program</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='database' value='MONGO' />
            </span>
          </label>
          {/*end::Option */}
          {/*begin:Option */}
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-danger'>
                  <img src={useDbPath('mysql-icon.png')} alt='' className='mh-40px p-2' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>MySQL</span>
                <span className='fs-6 fw-bold text-gray-400'>Basic MySQL database</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='database' value='MYSQL'/>
            </span>
          </label>
          {/*end::Option */}

          {/*begin:Option */}
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-warning'>
                  <img src={useDbPath('firebase-icon.png')} alt='' className='mh-40px p-2' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>Firebase</span>
                <span className='fs-6 fw-bold text-gray-400'>Google based app data management</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='database' value='FIREBASE' disabled={true} />
            </span>
          </label>
          {/*end::Option */}

          {/*begin:Option */}
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-info'>
                  <img src={useDbPath('postgresql-icon.png')} alt='' className='mh-45px p-3' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>PostgreSQL</span>
                <span className='fs-6 fw-bold text-gray-400'>Open-Source Relational DBMS</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='database' value='POSTGRES' disabled={true} />
            </span>
          </label>
          {/*end::Option */}
        </div>
      </div>

      <div className='text-danger mt-2'>
        <ErrorMessage name='database' />
      </div>

      <div className='fv-row '>
        <label className='form-label required'>Nick Name</label>

        <Field name='nickname' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='nickname' />
        </div>
        <div className='form-text'>
          To provide you with guidance and assistance in identifying your specific database.
        </div>
      </div>
    </div>
  )
}

export {Step1}
