import { AxiosResponse } from "axios";
import cgaasManifest from "../microServices/cgaas-manifest";
import cgaas from "../microServices/cgaas";
import cgaasFgai from "../microServices/cgaas-fgai";
import cgaasCbfg from "../microServices/cgaas-cbfg";
import cgaasFrontendGen from "../microServices/cgaas-frontend-gen";
import cgaasUsers from "../microServices/cgaas-users";
import gatewayManager from "../microServices/gateway-manager";

const checkCgaas = (): Promise<any>=>{
    return cgaas
      .get('healthCheck')
      .then((response:AxiosResponse<any>)=>response.data);
  }

const checkManifest = (): Promise<any>=>{
    return cgaasManifest
    .get('healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

  const checkFgai = (): Promise<any>=>{
    return cgaasFgai
    .get('healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

  const checkCbfg = (): Promise<any>=>{
    return cgaasCbfg
    .get('healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

  const checkUIGen = (workspaceId?:string, appId?:string, userId?:string): Promise<any>=>{
    return cgaasFrontendGen
    .get('healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

  const checkUsers = (workspaceId?:string, appId?:string, userId?:string): Promise<any>=>{
    return cgaasUsers
    .get('healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

  const checkGateway = (workspaceId?:string, appId?:string, userId?:string): Promise<any>=>{
    return gatewayManager
    .get('/healthCheck')
    .then((response:AxiosResponse<any>)=>response.data);
  }

export {checkCbfg,checkFgai,checkCgaas,checkGateway,checkManifest,checkUIGen,checkUsers}
