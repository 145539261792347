import React, {FC, useEffect, useRef, useState} from 'react'
import {Form, Formik, FormikValues} from 'formik'
import Swal from 'sweetalert2'
import {useAuth0} from '@auth0/auth0-react'
import {StepperComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {createDataSource, updateDataSource} from '../../../../../../services/dataSourceService'
import {createDataSourceSchemas, IDatasource, inits} from '../../../core/IDataSourceModels'
import {Step1} from '../steps/Step1'
import {Step2} from '../steps/Step2'
import {Step3} from '../steps/Step3'
import {SuccessPopup} from './SuccessPopup'
import {DataSourceModel} from '../../../../../../models/dataSource_model'

type Props = {
  initialData?: DataSourceModel
  onHide?: any
  updateTemporaryDataSources?:any
}

const DataSourceStepper: FC<Props> = ({initialData, onHide,updateTemporaryDataSources}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createDataSourceSchemas[0])
  const [initValues] = useState<IDatasource>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {user} = useAuth0()
  const [isSuccessDataSource, setIsSuccessDataSource] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  // Determine if it's an edit or create mode
  const isEditMode = !!initialData // Check if initialData exists

  // Define the initial values for Formik
  const initialFormValues: IDatasource = isEditMode ? initialData : initValues

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createDataSourceSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: IDatasource, actions: FormikValues) => {

    console.log("hi from login");
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      //check if its an edit
      if (isEditMode) {
        setIsSubmitting(true)
        const updateDataSourceData = await updateDataSource(values)
        if (updateDataSourceData.datasourceId !== '') {

          //temporary update the data source array in the data source list 
          if (updateTemporaryDataSources) {
            updateTemporaryDataSources(updateDataSourceData,"UPDATE");
          }
        setIsSubmitting(false)
          setIsSuccessDataSource(true)
        } else {
          Swal.fire({
            text: 'Something went wrong. Please try again!',
            icon: 'warning',
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: 'Ok Sure',
            cancelButtonText: 'Close',
            customClass: {
              confirmButton: 'btn fw-bold btn-danger',
              cancelButton: 'btn fw-bold btn-active-light-primary',
            },
          })
        }
      } else {
        setIsSubmitting(true)
        values.userId = user?.sub || ''
        const createDataSourceData = await createDataSource(values)
        if (createDataSourceData.datasourceId !== '') {

          //temporary update the data source array in the data source list 
          if (updateTemporaryDataSources) {
            updateTemporaryDataSources(createDataSourceData,"CREATE");
          }
        setIsSubmitting(false)
          setIsSuccessDataSource(true)
        } else {
          Swal.fire({
            text: 'Something went wrong. Please try again!',
            icon: 'warning',
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: 'Ok Sure',
            cancelButtonText: 'Close',
            customClass: {
              confirmButton: 'btn fw-bold btn-danger',
              cancelButton: 'btn fw-bold btn-active-light-primary',
            },
          })
        }
      }
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createDataSourceSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_modal_create_dataSource'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Database Type</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Configuration Info</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initialFormValues}
            onSubmit={submitStep}
          >
            {(formik) => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 formik={formik} />
                </div>

                <div data-kt-stepper-element='content' className='justify-content-center'>
                  {!isSuccessDataSource ? <Step3 /> : <SuccessPopup isEdit={isEditMode} />}
                </div>

                {!isSuccessDataSource && (
                  <div
                    className={`d-flex flex-stack ${
                      stepper.current?.currentStepIndex === 3 ? 'pt-0' : 'pt-15'
                    }`}
                  >
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3' disabled={isSubmitting}>
                        <span className='indicator-label'>
                          {!isSubmitButton && 'Continue'}
                          {isSubmitButton && !isSubmitting && 
                          <span>Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                          </span>
                        }


                          {isSubmitting && (
                        <span className='indicatorprogress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      )}

                        </span>
                      </button>
                    </div>
                  </div>
                )}

                {isSuccessDataSource && (
                  <div className='d-flex flex-center pt-1'>
                    <div>
                      <button
                        type='button'
                        onClick={() => onHide()}
                        className='btn btn-lg btn-primary me-3'
                      >
                        <span className='indicator-label'>Okay !!</span>
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {DataSourceStepper}
