/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import { DeploymentModel } from '../../../../../models/deployment_model'
import Swal from 'sweetalert2'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { CreateUpdateVersionControlModal } from '../../../version-control/components/modals/CreateUpdateVersionControl'
import { deleteDataSource } from '../../../../../services/dataSourceService'
import { checkVersionAvailability, getDeploymentImage } from '../models/iDeploymentModels'

type Props = {
  versionControlData?: any
  updateTemporaryVersionControl?: any
  type?: string
  onDelete: any
}

const DeploymentCard: FC<Props> = ({
  versionControlData,
  updateTemporaryVersionControl,
  onDelete,
  type,
}) => {
  // State for showing the edit DataSource modal
  const [showEditDataSourceModal, setshowEditDataSourceModal] = useState<boolean>(false)
  const [showViewDatSource, setShowViewDatSource] = useState<boolean>(false)

  // Function to handle the action change
  const handleActionChange = (e) => {
    if (e.target.value === 'EDIT') {
      // When "Edit" option is selected
      setshowEditDataSourceModal(true)
    } else if (e.target.value === 'DELETE') {
      // When "Delete" option is selected
      handleDelete(versionControlData)
    }

    // Reset the select input to its default value
    e.target.value = 'Action'
  }

  const handleDelete = (dataSource: DeploymentModel) => {
    Swal.fire({
      text: 'Are you sure you want to delete ' + dataSource.name + ' ?',
      icon: 'warning',
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      customClass: {
        confirmButton: 'btn fw-bold btn-danger',
        cancelButton: 'btn fw-bold btn-active-light-primary',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // const deletedApplication = await deleteDataSource(dataSource)
        // if (deletedApplication['operation'] == 'Success') {
        //   //temporary update the data source array in the data source list
        //   if (updateTemporaryVersionControl) {
        //     updateTemporaryVersionControl(dataSource, 'DELETE')
        //     onDelete(true)
        //   }

        //   Swal.fire({
        //     icon: 'success',
        //     text: 'You have deleted ' + dataSource.name + '!.',
        //     buttonsStyling: !1,
        //     confirmButtonText: 'Ok, got it!',
        //     customClass: {confirmButton: 'btn fw-bold btn-primary'},
        //   })
        // } else {
        //   Swal.fire('Error!', 'There was an error while deleting the datasource.', 'error')
        // }
      }
    })
  }

  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-2'>
            <div className='symbol symbol-75px symbol-circle'>
              <img alt='Pic' className='anyType p-5' src={getDeploymentImage(type || 'AWS')} />
              {/* {!versionControlData.assigned.length==null && <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>} */}
            </div>
          </div>
        </div>

        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
          {type}
        </a>

        {/* <div className='fw-bold text-gray-400 mb-6'>{versionControlData.status || 'Default'}</div> */}

        {/* <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-2 mx-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.located}</div>
            <div className='fw-bold text-gray-400'>Location</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.database}</div>
            <div className='fw-bold text-gray-400'>Database</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.assigned==null?"0":versionControlData.assigned.length}</div>
            <div className='fw-bold text-gray-400'>Usages</div>
          </div>
        </div> */}
        {showEditDataSourceModal && (
          <CreateUpdateVersionControlModal
            show={showEditDataSourceModal}
            handleCloseModal={() => setshowEditDataSourceModal(false)}
            initialData={versionControlData}
            updateTemporaryVersionControl={updateTemporaryVersionControl}
            type={type || ''}
          />
        )}
        {/* <ViewDataSourceModal
        show={showViewDatSource}
        handleCloseModal={()=>{setShowViewDatSource(false)}}
        dataSourceData={versionControlData}
        /> */}
        <div className='d-flex flex-center  my-5'>
          {/* <a
            href='#'
            className={
              checkVersionAvailability(versionControlData, type || '')
                ? 'btn btn-sm btn-primary'
                : 'btn btn-sm btn-secondary'
            }
            // className='btn btn-sm btn-primary'
            onClick={() => {
              if (checkVersionAvailability(versionControlData, type || '')) {
                // setshowEditDataSourceModal(true)
                
                }
              // setshowEditDataSourceModal(true)
            }}
          >
            <KTIcon iconName='wrench' className='fs-3' />
            Configure
          </a> */}
          <Link to={type?`/deployments/`+type.toLowerCase():''}>
            <div
              className='btn btn-sm btn-primary'
            >
              <KTIcon iconName='wrench' className='fs-3' />
              Configure
            </div>
          </Link>
          {/* { versionControlData.status!=='Default' && <a className=''>
            <select
              className='form-select btn pe-12 btn-sm btn-light form-select-solid'
              aria-label='Select Action'
              onChange={handleActionChange}
            >
              <option>Action</option>
              <option value='EDIT'>Edit</option>
              <option value='DELETE'>Delete</option>
            </select>
          </a>} */}
        </div>
      </div>
    </div>
  )
}

export {DeploymentCard}
