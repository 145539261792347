/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC, useState} from 'react'
import {getVersionImage} from './iVersionControlModels'
import {CreateUpdateVersionControlModal} from '../components/modals/CreateUpdateVersionControl'
import {KTIcon} from '../../../../../_metronic/helpers'

type Props = {
  versionControlData?: any
  updateTemporaryVersionControl?: any
  type?: string
}

const GitAccountCard: FC<Props> = ({
  versionControlData,
  updateTemporaryVersionControl,
  type,
}) => {
  const [showEditDataSourceModal, setshowEditDataSourceModal] = useState<boolean>(false)

  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-2'>
            <div className='symbol symbol-75px symbol-circle'>
              <img alt='Pic' className='anyType p-5' src={getVersionImage(type || 'GitHub')} />
              {/* {!versionControlData.assigned.length==null && <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>} */}
            </div>
          </div>
        </div>

        {/* <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
          {type}
        </a> */}

        <div className='fw-bold fs-7 text-gray-400 mt-3'>Nick Name</div>
        <div className='fw-bold mt-1 fs-5'>{versionControlData.nickName || 'Default'}</div>

        {/* <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-2 mx-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.located}</div>
            <div className='fw-bold text-gray-400'>Location</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.database}</div>
            <div className='fw-bold text-gray-400'>Database</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 mx-2 px-2 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{versionControlData.assigned==null?"0":versionControlData.assigned.length}</div>
            <div className='fw-bold text-gray-400'>Usages</div>
          </div>
        </div> */}
        {showEditDataSourceModal && (
          <CreateUpdateVersionControlModal
            show={showEditDataSourceModal}
            handleCloseModal={() => setshowEditDataSourceModal(false)}
            initialData={versionControlData}
            updateTemporaryVersionControl={updateTemporaryVersionControl}
            type={type || ''}
          />
        )}
        {/* <ViewDataSourceModal
        show={showViewDatSource}
        handleCloseModal={()=>{setShowViewDatSource(false)}}
        dataSourceData={versionControlData}
        /> */}
        {versionControlData.nickName != 'GIT-DEFAULT' && (
          <>
            <div className='d-flex flex-center  my-5'>
              <a
                href='#'
                // className={
                //   checkVersionAvailability(versionControlData, type || '')
                //     ? 'btn btn-sm btn-primary'
                //     : 'btn btn-sm btn-secondary'
                // }
                className='btn btn-sm btn-primary'
                onClick={() => {
                  // if (checkVersionAvailability(versionControlData, type || '')) {
                  //   // setshowEditDataSourceModal(true)

                  //   }
                  setshowEditDataSourceModal(true)
                }}
              >
                <KTIcon iconName='wrench' className='fs-3' />
                Re-Configure
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {GitAccountCard}
