import { SET_APPLICATION_STATUS_DATA, UPDATE_APPLICATIONS_STATUS_FIELD } from "./applicationStatusActions";

const initialState = {
  applicationStatusData: {
    API: null,
    CustomFunction: null,
    Dto: null,
    Relationship: null,
  },
};
const applicationStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLICATION_STATUS_DATA:
      return {
        ...state,
        applicationStatusData: action.payload,
      };
      case UPDATE_APPLICATIONS_STATUS_FIELD:
        return {
          ...state,
          applicationStatusData: {
            ...state.applicationStatusData,
            [action.fieldName]: action.fieldValue, 
          },
        };
      default:
        return state;
  }
};

export default applicationStatusReducer;



