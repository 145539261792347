import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

export interface IDatasource {
  datasourceId: string
  database: string
  userName: string
  password: string
  ssh: string
  credentials: string
  located: string
  url: string
  userId: string
  nickname:string
  status:string
  projectId:string
  assigned:AssignedApps[]
  
}
interface AssignedApps{
  workspaceName:string
  workspaceId:string
  appId:string
  appName:string
}
const createDataSourceSchemas = [
  Yup.object({
    database: Yup.string().required().label('Database Type'),
    nickname: Yup.string().required().label('Nickname'),
  }),
  Yup.object().shape({
    //url: Yup.string().required().label('Database URL'),
    //username: Yup.string().required().label('Username'),
    //password: Yup.string().required().label('Password'),
  }),
  Yup.object({
    located: Yup.string().required().label('Location Type'),
  }),
];


const inits: IDatasource = {
  datasourceId: '',
  database: 'MONGO',
  userName: '',
  password: '',
  ssh: '',
  credentials: '',
  located: 'CLOUD',
  url: '',
  userId: '',
  nickname:'',
  status:'Custom',
  projectId:'',
  assigned:[]
}
const getImageUrl = (databaseType: string) => {
  switch (databaseType) {
    case 'MONGO':
      return toAbsoluteUrl('/media/db/mongodb-icon.png')
    case 'MYSQL':
      return toAbsoluteUrl('/media/db/mysql-icon.png')
    case 'FIREBASE':
      return toAbsoluteUrl('/media/db/firebase-icon.png')
    case 'POSTGRES':
      return toAbsoluteUrl('/media/db/postgresql-icon.png')
    default:
      return toAbsoluteUrl('/media/db/anyType.png')
  }
}

export const getDatabaseBgColor = (databaseType: string) => {
  switch (databaseType) {
    case 'MONGO':
      return 'bg-light-success'
    case 'MYSQL':
      return 'bg-light-danger'
    case 'FIREBASE':
      return 'bg-light-warning'
    case 'POSTGRES':
      return 'bg-light-info'
    default:
      return 'bg-light-info'
  }
}

export {createDataSourceSchemas, inits,getImageUrl}
