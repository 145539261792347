import axios from 'axios';

export default axios.create({
	baseURL:  process.env.REACT_APP_CGAAS_GEN_URL ,
	headers: {
		'Content-type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Headers':
			'Origin, X-Requested-With, Content-Type, Accept, Authorization',
		'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
	},
});

