import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useAuth0 } from '@auth0/auth0-react'

const Error404: FC = () => {
  const {logout} = useAuth0()

  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
      <button
            type='button'
            className='btn btn-icon btn-primary btn-active-color-light btn-active-light m-3'
            style={{"width":"8rem"}}
            title='Logout'
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin + '/cgaas',
                },
              })
            }}
          >
            {/* <KTIcon iconName='element-11' className='fs-2 text-lg-1' /> */}
            <i
              className='ki-duotone ki-exit-left fs-2x me-2'
            >
              <i className='path1'></i>
              <i className='path2'></i>
            </i>
            Log Out
          </button>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error404}
