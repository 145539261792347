import {createPortal} from 'react-dom'
import {Form, Modal} from 'react-bootstrap'
import {DataSourceModel} from '../../../../../models/dataSource_model'
import {DataSourceStepper} from './configs/DataSourceStepper'
import {KTIcon} from '../../../../../../_metronic/helpers'

type Props = {
  show: boolean
  handleCloseModal: () => void
  initialData?: DataSourceModel
  dataSourceData?: DataSourceModel[]
  updateTemporaryDataSources?: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateUpdateDataSourceModal = ({
  show,
  handleCloseModal,
  initialData,
  updateTemporaryDataSources,
  dataSourceData,
}: Props) => {
  // Close the modal and reset states
  const handleClose = () => {
    handleCloseModal()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_dataSource'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-850px '
      backdrop='static'
      show={show}
      onHide={handleClose}
    >
      <div
        className='btn btn-sm btn-icon btn-active-color-primary closeModalCustom'
        onClick={handleClose}
      >
        <KTIcon className='fs-1' iconName='cross' />
      </div>

      <DataSourceStepper
        initialData={initialData}
        onHide={handleClose}
        updateTemporaryDataSources={updateTemporaryDataSources}
      />
    </Modal>,
    modalsRoot
  )
}

export {CreateUpdateDataSourceModal}
