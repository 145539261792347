/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import { RequestSuccessPopup } from './components/RequestSuccessPopup'
import { RequestedPopup } from './components/RequestedPopup'
import { RequestDeniedPopup } from './components/RequestDeniedPopup'
import { OraganizationDetailsForm } from './OraganizationDetailsForm'


const SubscriptionConfirmation = () => (
  <Routes>
      <Route path="detailspage" element={<OraganizationDetailsForm />} />
      <Route path='success' element={<RequestSuccessPopup />} />
      <Route path='pending' element={<RequestedPopup />} />
      <Route path='denied' element={<RequestDeniedPopup />} />
      <Route index element={<RequestedPopup />} />
  </Routes>
)

export {SubscriptionConfirmation}
