import {useEffect, useState} from 'react'
import {GitAccount} from '../../../../../models/versionControl_model'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {getAllGitAccount} from '../../../../../services/versionControlService'
import {useAuth0} from '@auth0/auth0-react'
import { CreateUpdateVersionControlModal } from '../../../version-control/components/modals/CreateUpdateVersionControl'
import { GitAccountCard } from '../../../version-control/core/gitAccountCard'

export function AkamaiList() {
  const [showEditDataSourceModal, setshowEditDataSourceModal] = useState<boolean>(false)
  const [gitAccountData, setGitAccountData] = useState<GitAccount[]>([])
  const {user} = useAuth0()
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   // Fetch the git account when the component mounts
  //   const userId = user?.sub
  //   getAllGitAccount(userId || '')
  //     .then((data) => {
  //       if (data) {
  //         setGitAccountData(data)
  //         setLoading(false) // Set loading to false after data is received
  //       } else {
  //         setGitAccountData([])
  //         setLoading(false) // Set loading to false after data is received
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle errors if needed
  //       console.error('Error fetching datasources:', error)
  //       setLoading(false) // Set loading to false on error
  //     })
  // }, [])

  //update data in the primary component without any API calls (CREATE, UPDATE, DELETE)
  const updateTemporaryGitAccount = (tempGitAccount: GitAccount, action?: string) => {
    setGitAccountData((prevData) => {
      // return [...prevData, tempGitAccount]

      // If the action is DELETE, remove the data source

      if (action == 'DELETE') {
        return prevData.filter(
          (gitAccount) => gitAccount.gitaccountId !== tempGitAccount.gitaccountId
        )
      } else {
        // Find the index of the application to update, if it exists
        const index = prevData?.findIndex(
          (dataSource) => dataSource.gitaccountId === tempGitAccount.gitaccountId
        )

        if (index !== -1) {
          // If the application already exists in the array, update it
          const updatedApplications = [...prevData]
          updatedApplications[index] = tempGitAccount
          return updatedApplications
        } else {
          // Create a new array by adding the tempDataSource to the existing data
          if (prevData){
            return [...prevData, tempGitAccount]
          } else {
            const newArray = [tempGitAccount]
            return newArray
          }
          
        }
      }
    })
  }
  return (
    <>
      {/* Header */}
      <div className='d-flex flex-wrap flex-stack'>
        <div
          className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
        >
          <h1 className='text-dark fw-bolder my-1 fs-3 lh-1'>Configure Akamai Accounts</h1>
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb fw-semibold fs-8 my-1'>
            <li className='breadcrumb-item text-muted'>
              <a href='../../demo4/dist/index.html' className='text-muted text-hover-primary'>
                Home
              </a>
            </li>
            <li className='breadcrumb-item text-muted'>CGaaS</li>
            <li className='breadcrumb-item text-muted'>Deployment Configurations</li>
            <li className='breadcrumb-item text-muted'>Akamai</li>
            {/*<li className='breadcrumb-item text-muted'>Wizards</li>*/}
          </ul>
          {/*end::Breadcrumb*/}
        </div>

        <div className='d-flex flex-wrap my-2'>
          {/* Search input */}
          <div className='me-4'>
            <div className='d-flex align-items-center position-relative'>
              <i className='ki-outline ki-magnifier fs-3 position-absolute ms-3'></i>
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-sm border-body bg-body w-170px ps-10'
                placeholder='Search GitHub Accounts'
                value={''}
              />
            </div>
          </div>

          <a
            href='#'
            // onClick={() => setshowEditDataSourceModal(true)}
            className='btn btn-primary btn-sm'
            data-bs-target='#kt_modal_create_dataSource'
          >
            New Akamai Account
          </a>
        </div>
      </div>

      {/* Create DataSource Button */}
      {showEditDataSourceModal && (
        <CreateUpdateVersionControlModal
          show={showEditDataSourceModal}
          handleCloseModal={() => setshowEditDataSourceModal(false)}
          initialData={{}}
          updateTemporaryVersionControl={updateTemporaryGitAccount}
          type={'GitHub'}
        />
      )}

      {/*begin::Content*/}
      <div className='content d-flex flex-column flex-column-fluid'>
        {/*begin::Container*/}
        <div>
          {/*begin::Row*/}
          <div className='row row-cols-1 row-cols-md-4 row-cols-xl-4 gap-3'>
            {/*begin:add new role card*/}
            <div className='card'>
              <div className='card-header'>
                {/*begin::Card title*/}
                <div className='card-title'>
                  <div className='fw-bold fs-3 text-gray-600 text-hover-primary'>
                    Add New Akamai Configuration
                  </div>
                </div>
                {/*end::Card title*/}
              </div>
              {/*begin::Card body*/}
              <div className='card-body d-flex flex-center'>
                {/*begin::Button*/}
                <button
                  type='button'
                  className='btn btn-clear d-flex flex-column flex-center'
                  // onClick={() => setshowEditDataSourceModal(true)}
                >
                  {/*begin::Illustration*/}
                  <img
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/10.png')}
                    alt=''
                    className='mw-100 mh-150px mb-7'
                  />
                  {/*end::Illustration*/}
                  {/*begin::Label*/}
                  {/* <div className='fw-bold fs-3 text-gray-600 text-hover-primary'>Add New Role</div> */}
                  {/*end::Label*/}
                </button>
                {/*begin::Button*/}
              </div>
              {/*begin::Card body*/}
            </div>
            {/*begin::Card*/}
            {gitAccountData && (
              <>
                {gitAccountData?.map((account, index) => (
                  <GitAccountCard
                    key={index}
                    versionControlData={account}
                    updateTemporaryVersionControl={updateTemporaryGitAccount}
                    // setUpRole={setUpRole}
                    // deleteModal={() => {
                    //   handleDelete(role)
                    // }}
                  ></GitAccountCard>
                ))}
              </>
            )}
          </div>
          {/*end::Row*/}
          {/*end::Modals*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Content*/}
    </>
  )
}
