import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import workspaceReducer from './workspaceReducer';
import applicationReducer from './applicationReducer';
import objectsReducer from './objectsReducer';
import applicationStatusReducer from './applicationStatusReducer';

// Combine all your reducers here if you have multiple reducers
const rootReducer = combineReducers({
  workspace: workspaceReducer,
  workspaceId: workspaceReducer,
  workspaceName: workspaceReducer,
  application: applicationReducer,
  applicationStatus: applicationStatusReducer,
  objects: objectsReducer,
});

// Create the Redux store
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
