import { SET_OBJECTS_DATA } from "./objectsActions";

const initialState = {
  objectsData: null,
};

const objectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OBJECTS_DATA:
      return {
        ...state,
        objectsData: action.payload,
      };
    default:
      return state;
  }
};

export default objectsReducer;



