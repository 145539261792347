import {toAbsoluteUrl} from '../../../helpers'
import {useGLoading} from '../../../layout/core/LoadingProvider'

const SplashScreenComponent = () => {
  const {isGLoading} = useGLoading()
  
  return (
    <>
      {isGLoading && (
        <div id='splash-screen' className='splash-screen-cgaas'>
          {<img src={toAbsoluteUrl('media/loader/loader.gif')} alt='Pic' />}
        </div>
      )}
    </>
  )
}

export default SplashScreenComponent
