// Define action types
export const SET_APPLICATION_STATUS_DATA = 'SET_APPLICATION_STATUS_DATA';
export const UPDATE_APPLICATIONS_STATUS_FIELD = "UPDATE_APPLICATION_STATUS_FIELD";

export const setApplicationStatusData = (data) => {
  return {
    type: SET_APPLICATION_STATUS_DATA,
    payload: data,
  }
}

export const updateApplicationStatusField = (fieldName, fieldValue) => {
  return {
    type: UPDATE_APPLICATIONS_STATUS_FIELD,
    fieldName,
    fieldValue,
  };
};