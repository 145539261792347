import {createPortal} from 'react-dom'
import {Form, Modal} from 'react-bootstrap'
import {DataSourceModel} from '../../../../../models/dataSource_model'
import {KTIcon, useDbPath} from '../../../../../../_metronic/helpers'
import {useRef} from 'react'
import ClipboardJS from 'clipboard'

type Props = {
  show: boolean
  handleCloseModal: () => void
  dataSourceData?: DataSourceModel
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewDataSourceModal = ({show, handleCloseModal, dataSourceData}: Props) => {
  const mongoIc = useDbPath('mongodb-icon.png')
  const postgresqpIc = useDbPath('postgresql-icon.png')
  const sqlIc = useDbPath('mysql-icon.png')
  const firebaseIc = useDbPath('firebase-icon.png')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const copyButtonRef = useRef<HTMLButtonElement | null>(null)

  // Close the modal and reset states
  const handleClose = () => {
    handleCloseModal()
  }

  const handleCopyButtonClick = () => {
    if (inputRef.current && copyButtonRef.current) {
      const clipboard = new ClipboardJS(copyButtonRef.current, {
        target: () => inputRef.current as HTMLInputElement,
      })

      clipboard.on('success', () => {
        // Handle success, e.g., show a success message
        alert('Text copied to clipboard')
      })

      clipboard.on('error', () => {
        // Handle error, e.g., show an error message
        alert('Failed to copy text to clipboard')
      })

      // Trigger the copy action programmatically
      inputRef.current.select()
      document.execCommand('copy')

      // Cleanup the clipboard instance
      clipboard.destroy()
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_dataSource'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px'
      show={show}
      onHide={handleClose}
    >
      <div className='card mb-5 mb-xl-8 px-0 py-0'>
        {/* <!--begin::Header--> */}
        <div className='card-header border-0 pt-4 pb-3 bg-primary'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 text-white'>View DataSource </span>
          </h3>
          <div className='d-flex align-items-center'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTIcon className='fs-1 text-white' iconName='cross' />
            </div>
          </div>
        </div>
        {/* <!--end::Header--> */}
        {/* <!--begin::Body--> */}
        <div className='card-body px-15 py-3 pt-10'>
       

          <div className='col-lg-12 mb-8'>
            <div className='fv-row'>
              {dataSourceData?.database === 'MONGO' && (
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-success'>
                        <img src={mongoIc} alt='' className='mh-40px p-2' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        MongoDB
                      </span>
                      <span className='fs-6 fw-bold text-gray-400'>NoSQL database program</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    {/* <Field className='form-check-input' type='radio' name='database' value='MONGO' /> */}
                  </span>
                </label>
              )}

              {dataSourceData?.database === 'MYSQL' && (
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-danger'>
                        <img src={sqlIc} alt='' className='mh-40px p-2' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>MySQL</span>
                      <span className='fs-6 fw-bold text-gray-400'>Basic MySQL database</span>
                    </span>
                  </span>
                </label>
              )}

              {dataSourceData?.database === 'FIREBASE' && (
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-warning'>
                        <img src={firebaseIc} alt='' className='mh-40px p-2' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        Firebase
                      </span>
                      <span className='fs-6 fw-bold text-gray-400'>
                        Google based app data management
                      </span>
                    </span>
                  </span>
                </label>
              )}

              {dataSourceData?.database === 'POSTGRESSQL' && (
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-info'>
                        <img src={postgresqpIc} alt='' className='mh-45px p-3' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        PostgreSQL
                      </span>
                      <span className='fs-6 fw-bold text-gray-400'>
                        Open-Source Relational DBMS
                      </span>
                    </span>
                  </span>
                </label>
              )}
            </div>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label'>Nick Name</label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='nickname'
              placeholder=''
              disabled={true}
              value={dataSourceData?.nickname || ''}
            />
          </div>

          {dataSourceData?.database === 'MONGO' && (
            <div className='fv-row mb-8'>
              <label className='form-label'>Database Url</label>

              <div className='d-flex'>
                <input
                  ref={inputRef}
                  type='text'
                  id='kt_referral_link_input' 
                  className='form-control form-control-solid me-3 flex-grow-1'
                  value={dataSourceData?.url || ''}
                  readOnly
                />
                <button
                  ref={copyButtonRef}
                  id='kt_referral_program_link_copy_btn'
                  className='btn btn-light-primary btn-flex p-3 pe-2'
                  data-clipboard-target='#kt_referral_link_input'
                  onClick={handleCopyButtonClick}
                >
                  <i className='ki-duotone ki-copy fs-2 btn-light-primary'></i>
                </button>
              </div>
            </div>
          )}

          {dataSourceData?.database === 'MYSQL' && (
            <div className='row fv-row'>
              <div className='fv-row col-6 mb-10'>
                <label className='form-label'>Username</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='nickname'
                  placeholder=''
                  disabled={true}
                  value={dataSourceData?.userName || ''}
                />
              </div>
              <div className='fv-row col-6 mb-10'>
                <label className='form-label'>Password</label>

                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  name='nickname'
                  placeholder=''
                  disabled={true}
                  value={dataSourceData?.password || ''}
                />
              </div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label'>Location Type</label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='nickname'
              placeholder=''
              disabled={true}
              value={dataSourceData?.located || ''}
            />
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label'>SSH</label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='nickname'
              placeholder=''
              disabled={true}
              value={dataSourceData?.ssh || ''}
            />
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ViewDataSourceModal}
